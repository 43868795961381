import { computed, effect, inject, Signal, signal, WritableSignal } from '@angular/core';
import { isNil } from 'lodash-es';
import { fromEvent, Subject, switchMap } from 'rxjs';

import { AuthStore } from '@stsm/auth/data/auth-store.service';
import { TranslationService } from '@stsm/i18n/global/services/translation.service';
import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { TargetMarket } from '@stsm/shared/models/target-market';
import { SentryService } from '@stsm/shared/services/sentry.service';
import { TargetMarketProvider } from '@stsm/shared/services/target-market-provider.service';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { VOID } from '@stsm/shared/util/rxjs.util';
import { UserUpdateBuilder } from '@stsm/user/models/user-update-builder';
import { USER_SERVICE } from '@stsm/user/services/tokens/user-service.token';
import { UserBaseService } from '@stsm/user/services/user-base.service';

import { AppNamePlugin } from '../plugins/app-name.plugin';
import { resolveTargetMarketFromLanguageTag } from '../util/resolve-target-market-from-language-tag';

export abstract class TargetMarketBaseService {
  /**
   * target market: Uses the target market from the environment in case that is static, otherwise the target market is
   * derived from the app name ({@link AppNamePlugin}) or the device locale (in that order)
   */
  protected readonly targetMarket: Signal<TargetMarket>;

  protected readonly updateUserTargetMarketRequest$: Subject<TargetMarket> = new Subject<TargetMarket>();

  protected readonly loggerService: LoggerService = inject(LoggerService);
  protected readonly translationService: TranslationService = inject(TranslationService);
  protected readonly authStore: AuthStore = inject(AuthStore);
  private readonly _sentryService: SentryService = inject(SentryService);
  private readonly _userService: UserBaseService = inject(USER_SERVICE);
  private readonly _environment: EnvironmentBase = inject(ENVIRONMENT);
  private readonly _targetMarketProvider: TargetMarketProvider = inject(TargetMarketProvider);

  private readonly _targetMarket: WritableSignal<TargetMarket> = signal('us');

  protected constructor() {
    this.targetMarket = computed(() =>
      this._environment.TARGET_MARKET !== 'dynamic' ? this._environment.TARGET_MARKET : (this._getLocalOverride() ?? this._targetMarket()),
    );

    effect(() => this._targetMarketProvider.setTargetMarket(this.targetMarket()));

    this.updateUserTargetMarketRequest$
      .pipe(
        switchMap((targetMarket: TargetMarket) =>
          this.authStore.isUserLoggedIn ? this._userService.updateUser(new UserUpdateBuilder().targetMarket(targetMarket)) : VOID,
        ),
      )
      .subscribe();

    // The languagechange event only works in the browser (web and mobile). It does not cover the
    // device language on mobile as that is implemented natively via the Capacitor Device plugin.
    // When changing the device language on a mobile device, for iOS the app is killed. For Android, the same seems to
    // be true.
    fromEvent(window, 'languagechange').subscribe(() => {
      this.loggerService.debug('[Target Market] language changed');
      this._setup();
    });
  }

  init(): void {
    this._setup();
  }

  /**
   * only use for mobile devtools.
   */
  protected toggle(): void {
    this._targetMarket.set(this.targetMarket() === 'core' ? 'us' : 'core');
  }

  protected async getTargetMarketBasedOnLocale(): Promise<TargetMarket> {
    try {
      const language = await this.getLanguageTag();

      return resolveTargetMarketFromLanguageTag(language);
    } catch (error) {
      this._sentryService.reportToSentry('Error with custom getLanguageTag', error);

      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
      return await Promise.reject(error);
    }
  }

  private _setup(): void {
    this._resolveInitialTargetMarket()
      .then((targetMarket: TargetMarket) => {
        this._targetMarket.set(targetMarket);
        this.loggerService.debug('[Target Market] using target market', targetMarket);
      })
      .catch((error: unknown) => {
        this.loggerService.warn('Error setting initial target market', error);
        this._sentryService.reportToSentry(`Error setting initial target market:`, error);
      });
  }

  private async _resolveInitialTargetMarket(): Promise<TargetMarket> {
    try {
      const { appName } = await AppNamePlugin.getAppName();

      console.log('[Target Market] app name', appName);

      // On mobile devices, the app name defines the target market
      if (!isNil(appName)) {
        return appName === 'Vaia' ? 'us' : 'core';
      }

      return this.getTargetMarketBasedOnLocale();
    } catch (error: unknown) {
      this._sentryService.reportToSentry('Error with AppNamePlugin.getAppName', error);

      return this.getTargetMarketBasedOnLocale();
    }
  }

  /**
   * For e2e environments, the precedence is as follows:
   * 1. static target market from environment
   * 2. local override if it has been set
   * 3. fallback to 'core'
   * @private
   */
  private _getLocalOverride(): TargetMarket | undefined {
    if (this._environment.name !== 'E2E') {
      return undefined;
    }

    const localOverride = localStorage.getItem(GlobalLocalStorageKey.TARGET_MARKET_OVERRIDE);

    return !isNil(localOverride) && ['core', 'us'].includes(localOverride) ? (localOverride as TargetMarket) : 'core';
  }

  protected abstract getLanguageTag(): Promise<string>;
}
