import { PremiumModalCallSource } from '@stsm/analytics/models/source-property-types';
import { PremiumPlan } from '@stsm/premium/models/premium-plan';

export abstract class PurchaseBaseService {
  abstract purchasePremiumPlan(options: {
    premiumPlan: PremiumPlan;
    source: PremiumModalCallSource;
    currentActivePlanID?: string;
  }): Promise<boolean>;
}
