import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';

import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { AmplitudeUserProperty } from '@stsm/analytics/models/amplitude-user-property';
import { BaseService } from '@stsm/shared/services/base.service';
import { JsonObject } from '@stsm/shared/types/json-object';
import { PremiumInfo, PremiumInfoSerializer } from '@stsm/user/models/premium-info';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

export abstract class PremiumBaseService {
  protected readonly baseService: BaseService = inject(BaseService);
  protected readonly userStoreFacade: UserStoreFacade = inject(UserStoreFacade);
  protected readonly analyticsBaseService: AnalyticsBaseService = inject(ANALYTICS_SERVICE);

  cancelSubscription(): Observable<object> {
    return this.baseService.delete('payments/paypal/', '');
  }

  refreshPremiumInfo(): Observable<PremiumInfo> {
    return this.baseService.get('payments/info/').pipe(
      map((res: JsonObject) => {
        const premiumInfo = PremiumInfoSerializer.fromJson(res);
        this.userStoreFacade.setPremiumInfo(premiumInfo);
        this.analyticsBaseService.setAmplitudeUserProperty(
          AmplitudeUserProperty.FREE_STUDYSETS_AND_SEARCH,
          premiumInfo.hasFreeStudysetsAndSearch,
        );

        return premiumInfo;
      }),
    );
  }
}
