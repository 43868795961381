import { booleanAttribute, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { TooltipDirective } from '@stsm/ui-components/tooltip';

const FEATURES = ['FREE_TRIAL.FEATURE_AD_FREE_STUDYING', 'FREE_TRIAL.FEATURE_STUDY_OFFLINE'];

const situationalFeatures = {
  withMoneyBackGuarantee: [...FEATURES, 'FREE_TRIAL.FEATURE_MONEY_BACK_GUARANTEE'],
  withTextbookSolutions: ['FREE_TRIAL.FEATURE_TEXTBOOK_SOLUTIONS', ...FEATURES],
};

@Component({
  selector: 'app-premium-features',
  imports: [TranslatePipe, TooltipDirective],
  templateUrl: './premium-features.component.html',
  styleUrls: ['./premium-features.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    role: 'list',
    ['data-cy']: 'premium-features',
  },
})
export class PremiumFeaturesComponent {
  @Input()
  showTextbooksPerk: boolean = false;

  @Input({ transform: booleanAttribute }) isCancellationMode: boolean = false;

  @Output()
  readonly moneyBackMoreClick: EventEmitter<void> = new EventEmitter<void>();

  protected get features(): string[] {
    if (this.showTextbooksPerk) {
      return situationalFeatures.withTextbookSolutions;
    }

    if (this.isCancellationMode) {
      return FEATURES;
    }

    return situationalFeatures.withMoneyBackGuarantee;
  }
}
