import { PlanDuration, PremiumPlan } from '@stsm/premium/models/premium-plan';

export interface PayOption extends PremiumPlan {
  descriptionKey: string;
  yearlyPrice: string;
}

const PAY_OPTION_BASE: PayOption = {
  descriptionKey: '',
  duration: PlanDuration.YEARLY,
  monthlyPrice: '0',
  overallPrice: '0',
  discount: 0,
  priceWithoutDiscount: '0',
  titleKey: '',
  productID: '',
  yearlyPrice: '0',
  hasTrial: true,
};

export const PAY_OPTIONS: Partial<PayOption>[] = [
  {
    descriptionKey: 'FREE_TRIAL.YEARLY.DESCRIPTION',
    duration: PlanDuration.YEARLY,
  },
  {
    descriptionKey: 'FREE_TRIAL.MONTHLY.DESCRIPTION',
    duration: PlanDuration.MONTHLY,
  },
];

export function mapPremiumPlansToPayOptions(premiumPlans: PremiumPlan[]): PayOption[] {
  return PAY_OPTIONS.map((payOption: Partial<PayOption>): PayOption => {
    const premiumPlan: PremiumPlan | undefined = premiumPlans.find((plan: PremiumPlan): boolean => plan.duration === payOption.duration);

    if (!premiumPlan) {
      return {
        ...PAY_OPTION_BASE,
        ...payOption,
      };
    }

    const yearlyPrice = premiumPlans.find((plan: PremiumPlan) => plan.duration === PlanDuration.YEARLY)?.priceWithoutDiscount || '0';

    return <PayOption>{
      ...payOption,
      ...premiumPlan,
      yearlyPrice,
    };
  });
}
