<div class="heading-container">
  <img alt="" height="60" width="60" [src]="LogoSource.HAT_WHITE" />
  <h1 class="display-md-semibold">{{ "PREMIUM.PREMIUM_SECOND_SESSION_DIALOG.HEADING" | translate }}</h1>
</div>

<div class="content-container">
  <button
    uiIconButton
    uiDialogClose
    primaryOnTheme
    class="close-button"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    [class.is-mobile-layout]="isMobileLayout()"
    (click)="closeClicked()"
  >
    <i class="app-icon-close"></i>
  </button>

  <h2 class="display-xxs-medium">{{ "PREMIUM.PREMIUM_SECOND_SESSION_DIALOG.HEADING_PREMIUM_FEATURES" | translate }}</h2>

  <ul class="premium-features">
    @for (premiumFeature of premiumFeatures; track $index) {
      <ui-icon-label role="listitem" [icon]="premiumFeature.icon">
        <p class="text-lg-semibold">{{ premiumFeature.description | translate }}</p>
        <p class="support-text text-sm-regular">{{ premiumFeature.supportText | translate }}</p>
      </ui-icon-label>
    }
  </ul>

  @if (!isMobileLayout()) {
    <div class="cta-container">
      <ng-container *ngTemplateOutlet="cta" />
    </div>
  }
</div>

@if (isMobileLayout()) {
  <div class="cta-container is-mobile-layout">
    <ng-container *ngTemplateOutlet="cta" />
  </div>
}

<ng-template #cta>
  <p>
    <span class="text-sm-bold">{{ "PREMIUM.PREMIUM_SECOND_SESSION_DIALOG.FREE_TRIAL" | translate }}, </span>
    <span class="text-sm-regular">
      {{ "PREMIUM.PREMIUM_SECOND_SESSION_DIALOG.PER_YEAR" | translate: { yearlyPrice: yearlyPrice() } }}.
      {{ "PREMIUM.DESIGN_TRIAL.CANCEL_ANYTIME" | translate }}
    </span>
  </p>

  <button uiButton premium primaryOnTheme size="lg" (click)="onTryPremiumClicked()">
    {{ "PREMIUM.PREMIUM_SECOND_SESSION_DIALOG.TRY_IT_FOR_FREE" | translate }}
  </button>
</ng-template>
