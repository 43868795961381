import { inject } from '@angular/core';
import { isNil } from 'lodash-es';
import {
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilKeyChanged,
  EMPTY,
  filter,
  firstValueFrom,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';

import { ActionId, ActionsHub, OpenPremiumDialogActionTrigger } from '@stsm/actions-hub';
import { AbTestService, ExperimentFlag } from '@stsm/analytics/global/services/ab-test.service';
import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { PremiumModalCallSource } from '@stsm/analytics/models/source-property-types';
import { TimeTrackFeature } from '@stsm/analytics/models/time-track-feature';
import { getElapsedTimeInDays } from '@stsm/date/global/functions/get-elapsed-time-in-days';
import { createSafeDate } from '@stsm/date/global/functions/safe-date';
import { ErrorHandlingService } from '@stsm/global/composite/services/error-handling.service';
import { FeatureTimeTrackingBaseService } from '@stsm/global/composite/services/feature-time-tracking-base.service';
import { FrontendSettingsStore } from '@stsm/global/composite/services/frontend-settings-store.service';
import { GlobalMessageService } from '@stsm/global/composite/services/global-message.service';
import { SessionService } from '@stsm/global/composite/services/session.service';
import { FEATURE_TIME_TRACKING_SERVICE } from '@stsm/global/composite/tokens/feature-time-tracking-service.token';
import { TranslationService } from '@stsm/i18n/global/services/translation.service';
import { PREMIUM_MODAL_ID, TRIAL_PREMIUM_MODAL_ID } from '@stsm/premium/models/constants/premium-modal-id';
import { HasPremiumDialogCloseEvent } from '@stsm/premium/models/has-premium-dialog-close-event';
import { HasPremiumDialogMoneyBackGuaranteeEvent } from '@stsm/premium/models/has-premium-dialog-money-back-guarantee-event';
import { HasPremiumDialogPurchaseEvent } from '@stsm/premium/models/has-premium-dialog-purchase-event';
import { PremiumFeature } from '@stsm/premium/models/premium-feature';
import { hasIndianCurrencyCode, PremiumPlan, PremiumPlansWithStorePricesResult } from '@stsm/premium/models/premium-plan';
import { PremiumPlansService } from '@stsm/premium/services/premium-plans.service';
import { PREMIUM_PLANS_SERVICE } from '@stsm/premium/services/tokens/premium-plans-service.token';
import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';
import { Tab } from '@stsm/shared/enums/tab';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { RouterStoreFacade } from '@stsm/shared/router-store/router-store-facade.service';
import { BrowserStorageService } from '@stsm/shared/services/browser-storage/browser-storage.service';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { InputPropertiesOf } from '@stsm/shared/types/input-properties-of';
import { PropertiesOf } from '@stsm/shared/types/properties-of';
import { firstValueFromOrNull, shareReplayRefCount } from '@stsm/shared/util/rxjs.util';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { DialogService } from '@stsm/ui-components/dialogs/services/dialog.service';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { PaymentProvider } from '@stsm/user/models/payment-provider';
import { PremiumInfo } from '@stsm/user/models/premium-info';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { CancelPremiumDialogService } from '../components/cancel-premium/cancel-premium-modal.service';
import { DesignYourTrialComponent } from '../components/design-your-trial/design-your-trial.component';
import { DiscountSubscriptionGiftDialogComponent } from '../components/discount-subscription-gift-dialog/discount-subscription-gift-dialog.component';
import { ManagePremiumComponent } from '../components/manage-premium/manage-premium.component';
import { PremiumDiscountDialogComponent } from '../components/premium-discount-dialog/premium-discount-dialog.component';
import { PremiumIntroductionDialogComponent } from '../components/premium-introduction-dialog/premium-introduction-dialog.component';
import { PremiumSecondSessionDialogComponent } from '../components/premium-second-session-dialog/premium-second-session-dialog.component';
import { hasSeenPremiumDiscount, setPremiumDiscountSeen } from '../util/premium-utils';

const PREMIUM_MODAL_OFFSET_IN_DAYS = 2;
const PREMIUM_MODAL_FREQUENCY_IN_DAYS = 7;
const PREMIUM_GIFT_SESSION_LATEST = 3;

export abstract class PremiumModalService {
  protected currentFeature: TimeTrackFeature | undefined = 'unknown';

  protected readonly userStoreFacade: UserStoreFacade = inject(UserStoreFacade);
  protected readonly browserStorageService: BrowserStorageService = inject(BrowserStorageService);
  protected readonly loggerService: LoggerService = inject(LoggerService);
  protected readonly sessionService: SessionService = inject(SessionService);
  protected readonly dialogService: DialogService = inject(DialogService);
  protected readonly routerStoreFacade: RouterStoreFacade = inject(RouterStoreFacade);
  protected readonly platformModalService: PlatformModalService = inject(PlatformModalService);
  protected readonly analyticsService: AnalyticsBaseService = inject(ANALYTICS_SERVICE);
  protected readonly featureTimeTrackingService: FeatureTimeTrackingBaseService = inject(FEATURE_TIME_TRACKING_SERVICE);
  protected readonly translationService: TranslationService = inject(TranslationService);
  protected readonly simpleDialogService: SimpleDialogService = inject(SimpleDialogService);
  protected readonly premiumPlansService: PremiumPlansService = inject(PREMIUM_PLANS_SERVICE);
  protected readonly environment: EnvironmentBase = inject(ENVIRONMENT);
  private readonly _cancelPremiumDialogService: CancelPremiumDialogService = inject(CancelPremiumDialogService);
  private readonly _globalMessageService: GlobalMessageService = inject(GlobalMessageService);
  private readonly _abTestService: AbTestService = inject(AbTestService);
  private readonly _frontendSettingsStore: FrontendSettingsStore = inject(FrontendSettingsStore);
  private readonly _errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  private readonly _actionsHub: ActionsHub = inject(ActionsHub);

  private readonly _premiumOfferReady$: Observable<boolean> = combineLatest([
    this._globalMessageService.isOnboardingRunning$,
    this.userStoreFacade.user$.pipe(distinctUntilKeyChanged('onboardingCompleted')),
    this.userStoreFacade.premiumInfo$,
  ]).pipe(
    switchMap(([isOnboardingRunning, user, premiumInfo]: [boolean, User, PremiumInfo]) => {
      if (isOnboardingRunning || !user.onboardingCompleted || premiumInfo.isPremium) {
        return of(false);
      }

      return this.routerStoreFacade.url$.pipe(
        filter((url: string) => url.includes(Tab.HOME)),
        debounceTime(500), // Prevents triggering modal if home route is only briefly visible
        map(() => true),
      );
    }),
    shareReplayRefCount(1),
  );

  private _isInitialised: boolean = false;

  protected constructor() {
    this._actionsHub.registerActionHandler(ActionId.OPEN_PREMIUM_DIALOG, (trigger: OpenPremiumDialogActionTrigger) => {
      this.openPremiumModal(trigger);

      return Promise.resolve();
    });

    this.featureTimeTrackingService.feature$.subscribe((currentFeature: TimeTrackFeature) => {
      this.currentFeature = currentFeature;
    });
  }

  init(): void {
    if (this._isInitialised) {
      return;
    }

    this._isInitialised = true;

    // reschedule dialogs if user switches account
    this.userStoreFacade.userAvailable$.subscribe(() => {
      void this._scheduleDialogs();
    });
  }

  openPremiumModal(params: OpenPremiumDialogActionTrigger): void {
    this.userStoreFacade.premiumInfo$.pipe(take(1)).subscribe((premiumInfo: PremiumInfo) => {
      const shouldShowTrialDialog = !premiumInfo.usedFreeTrial && !premiumInfo.isPremium;

      if (!shouldShowTrialDialog) {
        this.managePremium(params.source);

        return;
      }

      this.analyticsService.trackEvent({
        action: `premium_modal_open`,
        properties: { ...params, feature: params.feature ?? this.currentFeature },
      });

      this._openDesignTrialDialog(params);
    });
  }

  async openDiscountPremiumGiftDialog(options: { source?: PremiumModalCallSource; force?: boolean } = {}): Promise<void> {
    try {
      const premiumPlan = await firstValueFrom(this.premiumPlansService.getYearlyDiscountPremiumPlan());

      if (hasIndianCurrencyCode(premiumPlan)) {
        return;
      }
    } catch (error: unknown) {
      this.loggerService.warn(error);
    }

    this.analyticsService.trackEvent({ action: 'premium_gift_popup_open' });

    const shouldOpenGift: boolean | undefined = await firstValueFrom(
      this.platformModalService.schedule<object, boolean | undefined>({
        component: DiscountSubscriptionGiftDialogComponent,
        webOptions: {
          minWidth: 360,
          maxWidth: 360,
          width: 360,
        },
        useDialogType: true,
        allowBackdropDismiss: false,
        schedulerOptions: {
          condition: () => options.force || !hasSeenPremiumDiscount(this.environment),
          onScheduled: () => setPremiumDiscountSeen(),
        },
        disposeOnNavigation: false,
      }),
    );

    if (shouldOpenGift) {
      this.openDiscountPremiumModal({ source: options.source ?? 'discount_gift_popup' });
    } else {
      // only trigger if the user dismissed the popup
      this.analyticsService.trackEvent({ action: 'premium_gift_popup_close', properties: { step: 'preview' } });
    }
  }

  openDiscountPremiumModal(options: { source: PremiumModalCallSource }): void {
    this.premiumPlansService.getYearlyDiscountPremiumPlan().subscribe((premiumPlan: PremiumPlan) => {
      if (hasIndianCurrencyCode(premiumPlan)) {
        return;
      }

      this.analyticsService.trackEvent({
        action: 'premium_discount_offer_open',
        properties: { source: options.source },
      });

      const dialogRef = this.platformModalService.create({
        component: PremiumDiscountDialogComponent,
        data: <PropertiesOf<PremiumDiscountDialogComponent>>{
          premiumPlan,
        },
        cssClasses: ['premium-discount-dialog'],
        mobileOptions: {
          isAutoHeight: true,
        },
        allowBackdropDismiss: false,
      });

      this._registerCloseEvent(dialogRef, () => {
        this.analyticsService.trackEvent({
          action: 'premium_gift_popup_close',
          properties: { step: 'offer', source: options.source },
        });
      });
      this._registerOpenMoneyBackGuaranteePageEvent(dialogRef);
      this.registerPurchasePremiumPlanListener(dialogRef, options.source);
    });
  }

  managePremium(source: PremiumModalCallSource): void {
    this.premiumPlansService.getPremiumPlansWithStorePrices().subscribe(({ premiumPlans }: PremiumPlansWithStorePricesResult) => {
      this.analyticsService.trackEvent({ action: 'premium_manage_modal_open', properties: { source } });

      const dialogRef: DialogRef<ManagePremiumComponent> = this.platformModalService.create({
        component: ManagePremiumComponent,
        data: <PropertiesOf<ManagePremiumComponent>>{
          premiumPlans,
          paymentProvider: this.premiumPlansService.getPaymentProvider(),
        },
        mobileOptions: {
          isAutoHeight: true,
        },
        id: PREMIUM_MODAL_ID,
      });

      this._registerCloseEvent(dialogRef);
      this._registerOpenMoneyBackGuaranteePageEvent(dialogRef);
      this._registerCancelPlanEvent(dialogRef);
      this.registerPurchasePremiumPlanListener(dialogRef, source);
    });
  }

  private async _scheduleDialogs(): Promise<void> {
    const logout$ = this.userStoreFacade.userUnfiltered$.pipe(filter(isNil), shareReplayRefCount(1));
    const variantData = await this._abTestService.getExperimentValue(ExperimentFlag.PREMIUM_OFFER_SECOND_SESSION);

    // Discount Gift Dialog (50% off)
    this.sessionService.session$
      .pipe(
        takeWhile((session: number) => session <= PREMIUM_GIFT_SESSION_LATEST && !hasSeenPremiumDiscount(this.environment)),
        switchMap(() => this._premiumOfferReady$.pipe(filter(Boolean), take(1))),
        switchMap(() => this.openDiscountPremiumGiftDialog()),
        takeUntil(logout$),
      )
      .subscribe();

    // Existing Premium Modal on Session Start
    if (variantData.value !== 'treatment') {
      this.sessionService.session$
        .pipe(
          switchMap((session: number) => {
            return combineLatest([this.userStoreFacade.userAvailable$, this.userStoreFacade.premiumInfo$]).pipe(
              take(1),
              tap(([user, premiumInfo]: [User, PremiumInfo]) => {
                if (premiumInfo.usedFreeTrial || premiumInfo.isPremium) {
                  return;
                }

                if (this._shouldShowPremiumModalOnSessionStart(user, session)) {
                  void this._openPremiumModalOnSessionStart(session);
                }
              }),
            );
          }),
          takeUntil(logout$),
        )
        .subscribe();
    }

    // Experimental Premium Offer the Session after the Gift Dialog.
    this.sessionService.session$
      .pipe(
        withLatestFrom(this._hasSeenPremiumOfferAtSecondSession(this.environment)),
        takeWhile(([_, hasSeenPremiumOfferAtSecondSession]: [number, boolean]) => !hasSeenPremiumOfferAtSecondSession),
        switchMap(([session]: [number, boolean]) => {
          // Additional session check because the user can, in theory, miss the gift dialog
          // (refresh during onboarding increases the session count)
          const isOfferDue = hasSeenPremiumDiscount(this.environment) || session > PREMIUM_GIFT_SESSION_LATEST;

          if (!isOfferDue) {
            return EMPTY;
          }

          return this.userStoreFacade.premiumInfo$.pipe(
            switchMap((premiumInfo: PremiumInfo) => {
              // Exclude premium users and users who have used their free trial from experiment
              if (premiumInfo.isPremium || premiumInfo.usedFreeTrial) {
                return this._setPremiumOfferSecondSessionSeen();
              }

              return this._premiumOfferReady$.pipe(
                filter(Boolean),
                take(1),
                switchMap(() => {
                  return this.premiumPlansService.getDesignTrialPremiumPlans().pipe(
                    map(({ premiumPlans }: PremiumPlansWithStorePricesResult) => premiumPlans),
                    map((premiumPlans: PremiumPlan[]) => premiumPlans.find((plan: PremiumPlan) => plan.hasTrial)),
                    switchMap((freeTrialPlan: PremiumPlan | undefined) => {
                      if (isNil(freeTrialPlan)) {
                        return throwError(() => 'No plan with trial found');
                      }

                      // Exclude Indian users from experiment
                      if (hasIndianCurrencyCode(freeTrialPlan)) {
                        return this._setPremiumOfferSecondSessionSeen().pipe(switchMap(() => EMPTY));
                      }

                      return of(freeTrialPlan);
                    }),
                    catchError((error: unknown) => {
                      // Report to Sentry if fetch failed or plan was not found
                      this._errorHandlingService.handleError({
                        error,
                        messageToDisplay: 'FREE_TRIAL.ERROR_FETCHING_PLANS',
                        trackingErrorValue: 'premium_modal_second_session_plan_unavailable',
                        sentryMessage: '[premium-offer-second-session] Failed to get free trial plan',
                      });

                      return EMPTY;
                    }),
                  );
                }),
                switchMap((freeTrialPlan: PremiumPlan) => {
                  // For control, track exposure and mark dialog as seen.
                  // We don't want this dialog to appear for those users past the experiment runtime.
                  if (variantData.value !== 'treatment') {
                    this._trackPremiumOfferSecondSessionExposure();

                    return this._setPremiumOfferSecondSessionSeen();
                  }

                  const introDialogRef = this._openPremiumIntroductionDialog();

                  return introDialogRef.afterOpened().pipe(
                    tap(() => this._trackPremiumOfferSecondSessionExposure()),
                    switchMap(() => introDialogRef.afterClosed()),
                    switchMap(() => this._openPremiumSecondSessionDialog(freeTrialPlan).afterOpened()),
                    switchMap(() => this._setPremiumOfferSecondSessionSeen()),
                  );
                }),
              );
            }),
          );
        }),
        takeUntil(logout$),
      )
      .subscribe();
  }

  private _openPremiumIntroductionDialog(): DialogRef {
    return this.platformModalService.create({
      component: PremiumIntroductionDialogComponent,
      webOptions: {
        minWidth: 500,
        maxWidth: 500,
        width: 500,
      },
      mobileOptions: {
        isAutoHeight: true,
      },
      allowBackdropDismiss: false,
    });
  }

  private _openPremiumSecondSessionDialog(freeTrialPremiumPlan: PremiumPlan): DialogRef {
    return this.platformModalService.create({
      component: PremiumSecondSessionDialogComponent,
      data: {
        freeTrialPremiumPlan,
      },
      webOptions: {
        minWidth: 500,
        maxWidth: 800,
        width: 800,
        maxHeight: 500,
      },
      mobileOptions: {
        isFullscreen: true,
      },
      allowBackdropDismiss: false,
    });
  }

  private _openDesignTrialDialog(params: OpenPremiumDialogActionTrigger): void {
    forkJoin([this.premiumPlansService.getDesignTrialPremiumPlans(), this.userStoreFacade.premiumInfo$.pipe(take(1))]).subscribe(
      ([{ premiumPlans, hasPriceFetchingError }, premiumInfo]: [PremiumPlansWithStorePricesResult, PremiumInfo]) => {
        const showStudysetAndSearchSlide = !premiumInfo.hasFreeStudysetsAndSearch;

        const dialogRef: DialogRef<DesignYourTrialComponent> = this.platformModalService.create({
          component: DesignYourTrialComponent,
          data: <InputPropertiesOf<DesignYourTrialComponent>>{
            yearlyPremiumPlans: premiumPlans,
            showFeaturesSwiper: true,
            specificPremiumFeatures: [
              ...(showStudysetAndSearchSlide ? [PremiumFeature.UNLIMITED_STUDYSETS, PremiumFeature.UNLIMITED_SEARCH_PAGE] : []),
            ],
            highlightedFeature: params.highlightedFeature,
          },
          mobileOptions: {
            isFullscreen: true,
            shouldApplySafeAreaTop: false,
          },
          webOptions: {
            maxWidth: 500,
          },
          id: TRIAL_PREMIUM_MODAL_ID,
          allowBackdropDismiss: false,
        });

        this._registerCloseEvent(dialogRef, () => this._trackPremiumModalCloseEvent(params));
        this._registerOpenMoneyBackGuaranteePageEvent(dialogRef);
        this.registerPurchasePremiumPlanListener(dialogRef, params.source);

        if (hasPriceFetchingError) {
          this.simpleDialogService.showError('PREMIUM.PLAN_PAGE.ERROR_FETCHING_PRICES');
        }
      },
    );
  }

  private _registerCancelPlanEvent(dialogRef: DialogRef<ManagePremiumComponent>): void {
    dialogRef.componentInstance?.instance.cancelPlan
      .pipe(
        tap(() => {
          this.analyticsService.trackEvent({
            action: 'premium_manage_modal_interact',
            properties: { action: 'select_cancel_premium' },
          });
        }),
        withLatestFrom(this.userStoreFacade.premiumInfo$),
        switchMap(([premiumPlan, premiumInfo]: [PremiumPlan, PremiumInfo]) => {
          if (premiumInfo.provider !== PaymentProvider.PAYPAL) {
            this.loggerService.warn('Cancelling a subscription other than Paypal on mobile is only possible via the respective store.');

            return EMPTY;
          }

          return this._cancelPremiumDialogService.show(premiumPlan.productID).pipe(
            tap((didCancelPremium: boolean | undefined) => {
              if (didCancelPremium) {
                dialogRef.dismiss();
              }
            }),
          );
        }),
        takeUntil(dialogRef.afterClosed()),
      )
      .subscribe();
  }

  private _registerOpenMoneyBackGuaranteePageEvent(dialogRef: DialogRef<HasPremiumDialogMoneyBackGuaranteeEvent>): void {
    dialogRef.componentInstance?.instance.moneyBackGuaranteeClicked.pipe(takeUntil(dialogRef.afterClosed())).subscribe(() => {
      this.analyticsService.trackEvent({
        action: 'premium_modal_show_guarantee',
      });

      this.openMoneyBackGuaranteeLink();
    });
  }

  private _registerCloseEvent(dialogRef: DialogRef<HasPremiumDialogCloseEvent>, trackEventFn?: () => void): void {
    dialogRef.componentInstance?.instance.closeClicked.pipe(take(1), takeUntil(dialogRef.afterClosed())).subscribe(() => {
      dialogRef?.dismiss();
      trackEventFn?.();
    });
  }

  private _trackPremiumModalCloseEvent(params: OpenPremiumDialogActionTrigger): void {
    this.analyticsService.trackEvent({
      action: 'premium_modal_close',
      properties: { ...params, feature: params.feature ?? this.currentFeature },
    });
  }

  private async _openPremiumModalOnSessionStart(currentSession: number): Promise<void> {
    this.browserStorageService.setItemLocalStorage(GlobalLocalStorageKey.PREMIUM_MODAL_EXPERIMENT_LAST_SEEN, new Date().toISOString());

    // later to be used to double the frequency every time
    const counter =
      this.browserStorageService.getItemLocalStorage<number>(GlobalLocalStorageKey.PREMIUM_MODAL_ON_SESSION_START_COUNTER) ?? 0;
    this.browserStorageService.setItemLocalStorage(GlobalLocalStorageKey.PREMIUM_MODAL_ON_SESSION_START_COUNTER, counter + 1);
    this.browserStorageService.setItemLocalStorage<number>(
      GlobalLocalStorageKey.PREMIUM_MODAL_EXPERIMENT_LAST_SEEN_IN_SESSION,
      currentSession,
    );

    await firstValueFromOrNull(
      this.dialogService.isAnyDialogOpen$.pipe(
        debounceTime(500),
        filter((isAnyDialogOpen: boolean) => !isAnyDialogOpen),
      ),
    );

    this.openPremiumModal({ source: 'session_start' });
  }

  private _shouldShowPremiumModalOnSessionStart(user: User, session: number): boolean {
    if (session <= PREMIUM_GIFT_SESSION_LATEST) {
      return false;
    }

    const dateJoined = user.appUser.dateJoined;
    const lastSeen = this.browserStorageService.getItemLocalStorage<string>(GlobalLocalStorageKey.PREMIUM_MODAL_EXPERIMENT_LAST_SEEN);
    const lastSeenInSession = this.browserStorageService.getItemLocalStorage<number>(
      GlobalLocalStorageKey.PREMIUM_MODAL_EXPERIMENT_LAST_SEEN_IN_SESSION,
    );

    const daysSinceSignup = getElapsedTimeInDays(createSafeDate(dateJoined));
    const daysSinceLastSeen = isNil(lastSeen) ? Number.MAX_SAFE_INTEGER : getElapsedTimeInDays(createSafeDate(lastSeen));
    const sessionsSinceLastSeen = isNil(lastSeenInSession) ? Number.MAX_SAFE_INTEGER : Math.abs(session - lastSeenInSession);

    if (daysSinceSignup <= PREMIUM_MODAL_OFFSET_IN_DAYS) {
      return false;
    }

    if (sessionsSinceLastSeen <= 2) {
      return false;
    }

    return daysSinceLastSeen > PREMIUM_MODAL_FREQUENCY_IN_DAYS;
  }

  private _hasSeenPremiumOfferAtSecondSession(environment: EnvironmentBase): Observable<boolean> {
    const isEnabledDuringTesting = localStorage.getItem(GlobalLocalStorageKey.E2E_ENABLE_PREMIUM_OFFER_SECOND_SESSION);

    if (environment.name === 'E2E' && !isEnabledDuringTesting) {
      return of(true);
    }

    return this._frontendSettingsStore
      .getSetting$<boolean>('hasSeenPremiumOfferSecondSession')
      .pipe(map((hasSeenPremiumOffer: boolean | undefined) => hasSeenPremiumOffer === true));
  }

  private _trackPremiumOfferSecondSessionExposure(): void {
    void this._abTestService.trackCustomExposure({
      flag: ExperimentFlag.PREMIUM_OFFER_SECOND_SESSION,
      eventAction: 'exposure_premium_offer_second_session',
    });
  }

  private _setPremiumOfferSecondSessionSeen(): Observable<void> {
    return this._frontendSettingsStore.patchSettings({ hasSeenPremiumOfferSecondSession: true });
  }

  abstract openMoneyBackGuaranteeLink(): void;

  protected abstract registerPurchasePremiumPlanListener(
    dialogRef: DialogRef<HasPremiumDialogPurchaseEvent>,
    source: PremiumModalCallSource,
  ): void;
}
