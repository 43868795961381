import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { LogoSource } from '@stsm/shared/models/logo-source';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { ConfettiCanvasComponent } from '@stsm/ui-components/confetti-canvas';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';

import { PremiumModalService } from '../../services/premium-modal.service';
import { PREMIUM_MODAL_SERVICE } from '../../tokens/premium-modal-service.token';
import { PremiumValuesComponent } from '../premium-values/premium-values.component';

@Component({
  selector: 'app-premium-welcome-dialog',
  imports: [ButtonComponent, TranslatePipe, PremiumValuesComponent, ConfettiCanvasComponent, IconButtonComponent],
  templateUrl: './premium-welcome-dialog.component.html',
  styleUrl: './premium-welcome-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PremiumWelcomeDialogComponent {
  protected readonly logoSource: LogoSource = LogoSource.HAT_WHITE;

  constructor(
    private readonly _dialogRef: DialogRef,
    @Inject(PREMIUM_MODAL_SERVICE) private readonly _premiumModalService: PremiumModalService,
  ) {}

  protected close(isCta: boolean = false): void {
    this._dialogRef.dismiss(isCta);
  }

  protected onMoneyBackClicked(): void {
    this._premiumModalService.openMoneyBackGuaranteeLink();
  }
}
