import { Inject, Injectable } from '@angular/core';
import { defer, firstValueFrom, map, of, switchMap, take } from 'rxjs';

import { AbTestService, ExperimentFlag } from '@stsm/analytics/global/services/ab-test.service';
import { PremiumModalCallSource } from '@stsm/analytics/models/source-property-types';
import { PremiumWelcomeDialogService } from '@stsm/premium/feature/components/premium-welcome-dialog/premium-welcome-dialog.service';
import { PremiumPlan } from '@stsm/premium/models/premium-plan';
import { PurchaseBaseService } from '@stsm/premium/services/purchase-base.service';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { VOID } from '@stsm/shared/util/rxjs.util';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';
import { PremiumInfo } from '@stsm/user/models/premium-info';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { AnalyticsService } from '../../shared/services/analytics.service';

import { PaymentDialogComponent } from './payment-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PaymentDialogService extends PurchaseBaseService {
  constructor(
    @Inject(ENVIRONMENT) private readonly _environment: EnvironmentBase,
    private readonly _platformModalService: PlatformModalService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _premiumWelcomeDialogService: PremiumWelcomeDialogService,
    private readonly _layoutStore: LayoutStore,
    private readonly _abTestService: AbTestService,
  ) {
    super();
  }

  async show(premiumPlan: PremiumPlan, source: PremiumModalCallSource): Promise<DialogRef<PaymentDialogComponent, boolean | undefined>> {
    const newPaymentSelectionExperiment =
      (await this._abTestService.getExperimentValue(ExperimentFlag.NEW_PAYMENT_SELECTION)).value === 'treatment';

    if (newPaymentSelectionExperiment && !this._layoutStore.isMobileLayout()) {
      this._platformModalService.dismiss();
    }

    const modalOptions = newPaymentSelectionExperiment
      ? {
          webOptions: {
            maxWidth: '600px',
          },
          mobileOptions: {
            isAutoHeight: true,
          },
          useDialogType: false,
        }
      : {
          webOptions: {
            width: '300px',
          },
          useDialogType: true,
        };

    this._analyticsService.trackEvent({
      action: 'premium_web_payment_method_open',
      properties: { source },
    });

    return this._platformModalService.create({
      component: PaymentDialogComponent,
      data: { premiumPlan, source, newPaymentSelectionExperiment },
      ...modalOptions,
    });
  }

  override async purchasePremiumPlan({
    premiumPlan,
    source,
  }: {
    premiumPlan: PremiumPlan;
    source: PremiumModalCallSource;
    currentActivePlanID?: string;
  }): Promise<boolean> {
    // Simulate successful purchase in e2e tests
    if (this._environment.name === 'E2E') {
      return true;
    }

    const showPaymentDialog$ = defer(() => this.show(premiumPlan, source)).pipe(
      switchMap((dialogRef: DialogRef) => dialogRef.afterClosed()),
      switchMap((hasPurchased: boolean | undefined) => {
        if (hasPurchased) {
          return this._userStoreFacade.premiumInfo$.pipe(
            take(1),
            switchMap((premiumInfo: PremiumInfo) => {
              if (premiumInfo.isPremium && !this._layoutStore.isPhoneLayout()) {
                return this._premiumWelcomeDialogService.openPremiumWelcomeDialog();
              }

              return VOID;
            }),
            map(() => hasPurchased),
          );
        }

        return of(false);
      }),
    );

    return firstValueFrom(showPaymentDialog$);
  }
}
