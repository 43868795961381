<div class="paypal-wrapper">
  @if (newPaymentSelectionExperiment()) {
    <div class="payment-buttons">
      <div id="paypal-button-container"></div>
      <div id="paypal-card-button-container"></div>
    </div>
  } @else {
    <div class="paypal-buttons" id="paypal-button-container"></div>
  }
</div>
