import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';

import { PayOption } from './pay-option';

@Component({
  selector: 'app-pay-option',
  templateUrl: './pay-option.component.html',
  styleUrls: ['./pay-option.component.scss'],
  imports: [NgIf, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayOptionComponent {
  @Input({ required: true }) payOption!: PayOption;

  @Input()
  @HostBinding('class.selected')
  isSelected: boolean = false;

  @Input()
  isActive: boolean = false;

  @HostBinding('attr.data-testid')
  get dataTestId(): string {
    return `pay-option-${this.payOption?.duration}`;
  }
}
