import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { DialogCloseDirective } from '@stsm/ui-components/dialogs/components/dialog-close.directive';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';

@Component({
  selector: 'app-discount-subscription-gift-dialog',
  imports: [TranslatePipe, ButtonComponent, DialogCloseDirective, IconButtonComponent],
  templateUrl: './discount-subscription-gift-dialog.component.html',
  styleUrl: './discount-subscription-gift-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountSubscriptionGiftDialogComponent {
  constructor(private readonly _dialogRef: DialogRef) {}

  openGift(): void {
    this._dialogRef.dismiss(true);
  }
}
