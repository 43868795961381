import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { InputPropertiesOf } from '@stsm/shared/types/input-properties-of';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { CancelPremiumComponent } from './cancel-premium.component';

@Injectable({ providedIn: 'root' })
export class CancelPremiumDialogService {
  constructor(private readonly _platformModalService: PlatformModalService) {}

  show(productId: string): Observable<boolean | undefined> {
    return this._platformModalService
      .create<InputPropertiesOf<CancelPremiumComponent>, CancelPremiumComponent, boolean | undefined>({
        component: CancelPremiumComponent,
        data: { productId },
        webOptions: { width: 'min(480px, 80vw)' },
        mobileOptions: { isAutoHeight: true },
        autoFocus: true,
      })
      .afterClosed();
  }
}
