import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  input,
  InputSignal,
  OnInit,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { isNil } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';

import { ActionId, ActionsHub } from '@stsm/actions-hub';
import { AbTestService, ExperimentFlag } from '@stsm/analytics/global/services/ab-test.service';
import { PremiumModalCallSource } from '@stsm/analytics/models/source-property-types';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/global/services/translation.service';
import { PlanDuration, PremiumPlan } from '@stsm/premium/models/premium-plan';
import { LogoSource } from '@stsm/shared/models/logo-source';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { IconButtonComponent } from '@stsm/ui-components/button';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { OrSeparatorComponent } from '@stsm/ui-components/or-separator/or-separator.component';
import { PremiumInfo } from '@stsm/user/models/premium-info';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { AnalyticsService } from '../../shared/services/analytics.service';

import { PaymentProviderFakeDoorComponent } from './payment-provider-fake-door/payment-provider-fake-door.component';
import { PaypalComponent } from './paypal/paypal.component';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss'],
  imports: [PaypalComponent, TranslatePipe, IconButtonComponent, PaymentProviderFakeDoorComponent, OrSeparatorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'payment-dialog',
    '[attr.new-payment-selection-variant]': 'newPaymentSelectionExperiment()',
  },
})
export class PaymentDialogComponent implements OnInit {
  @Input({ required: true }) source!: PremiumModalCallSource;

  readonly premiumPlan: InputSignal<PremiumPlan> = input.required<PremiumPlan>();

  readonly newPaymentSelectionExperiment: InputSignal<boolean> = input<boolean>(false);

  protected readonly isMobileLayout: Signal<boolean> = this._layoutStore.isMobileLayout;

  protected isTransactionInProcess: boolean = false;

  protected readonly priceInfo: Signal<string> = computed(() => {
    const premiumPlan = this.premiumPlan();

    if (premiumPlan.duration === PlanDuration.MONTHLY) {
      return this._translationService.get('FREE_TRIAL.SELECTED_PLAN_INFO_MONTHLY', { price: premiumPlan.monthlyPrice });
    }

    const introductoryOffer = premiumPlan.introductoryOffer;

    if (!isNil(introductoryOffer)) {
      return this._translationService.get('FREE_TRIAL.SELECTED_PLAN_INFO_YEARLY_TRIAL', {
        trialInfo: this._translationService.get('FREE_TRIAL.PAID_TRIAL_MONTH_INFO', {
          count: introductoryOffer.billingCycleCount,
          price: introductoryOffer.displayPrice,
        }),
        price: premiumPlan.monthlyPrice,
      });
    }

    return this._translationService.get(
      premiumPlan.hasTrial ? 'FREE_TRIAL.SELECTED_PLAN_INFO_YEARLY' : 'FREE_TRIAL.SELECTED_PLAN_INFO_YEARLY_NO_TRIAL',
      { price: premiumPlan.monthlyPrice },
    );
  });

  protected readonly usedFreeTrial: Signal<boolean> = toSignal(
    this._userStoreFacade.premiumInfo$.pipe(
      map((premiumInfo: PremiumInfo) => premiumInfo.usedFreeTrial),
      distinctUntilChanged(),
    ),
    { initialValue: false },
  );

  protected readonly LogoSource: typeof LogoSource = LogoSource;

  protected readonly isFakeDoorTreatment: WritableSignal<boolean> = signal(false);

  constructor(
    private readonly _dialogRef: DialogRef<PaymentDialogComponent>,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _translationService: TranslationService,
    private readonly _abTestService: AbTestService,
    private readonly _actionsHub: ActionsHub,
    private readonly _layoutStore: LayoutStore,
    private readonly _analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    void this._initializePaymentMethodsFakeDoorExperiment();
  }

  onTransactionCompleted(): void {
    this._dialogRef.dismiss(true);
  }

  onTransactionInProcess(isInProcess: boolean): void {
    this.isTransactionInProcess = isInProcess;
    this._dialogRef.canClose = !isInProcess;
  }

  onCloseDialog(): void {
    if (!this.isTransactionInProcess) {
      this._analyticsService.trackEvent({ action: 'premium_web_payment_method_close' });
      this._dialogRef.dismiss(false);
    }
  }

  onBack(): void {
    if (!this.isTransactionInProcess) {
      this._dialogRef.dismiss(false);
      void this._actionsHub.triggerAction(ActionId.OPEN_PREMIUM_DIALOG, { source: 'payment_dialog_back' });
    }
  }

  private async _initializePaymentMethodsFakeDoorExperiment(): Promise<void> {
    const variantData = await this._abTestService.getExperimentValue(ExperimentFlag.PAYMENT_METHODS_FAKE_DOOR);

    this.isFakeDoorTreatment.set(variantData.value === 'treatment');
  }
}
