import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';

import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { ButtonComponent } from '@stsm/ui-components/button';
import { DialogCloseDirective } from '@stsm/ui-components/dialogs/components/dialog-close.directive';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

@Component({
  selector: 'app-premium-introduction-dialog',
  templateUrl: './premium-introduction-dialog.component.html',
  styleUrl: './premium-introduction-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, ButtonComponent, DialogCloseDirective, TranslatePipe],
})
export class PremiumIntroductionDialogComponent {
  protected readonly userFirstName$: Observable<string> = this._userStoreFacade.user$.pipe(map((user: User) => user.appUser.firstName));

  constructor(
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _dialogRef: DialogRef,
  ) {}

  continue(): void {
    this._dialogRef.dismiss();
  }
}
