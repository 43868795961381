import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Inject, input, InputSignal, Signal } from '@angular/core';

import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { premiumFeatures } from '@stsm/premium/models/premium-feature';
import { FALLBACK_PRICE, PremiumPlan } from '@stsm/premium/models/premium-plan';
import { PurchaseBaseService } from '@stsm/premium/services/purchase-base.service';
import { PURCHASE_SERVICE } from '@stsm/premium/services/tokens/purchase-service.token';
import { LogoSource } from '@stsm/shared/models/logo-source';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { DialogCloseDirective } from '@stsm/ui-components/dialogs/components/dialog-close.directive';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { IconLabelComponent } from '@stsm/ui-components/icon-label';

@Component({
  selector: 'app-premium-second-session-dialog',
  templateUrl: './premium-second-session-dialog.component.html',
  styleUrl: './premium-second-session-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, TranslatePipe, DialogCloseDirective, NgTemplateOutlet, IconButtonComponent, IconLabelComponent],
})
export class PremiumSecondSessionDialogComponent {
  readonly freeTrialPremiumPlan: InputSignal<PremiumPlan> = input.required();

  protected readonly LogoSource: typeof LogoSource = LogoSource;

  protected readonly premiumFeatures: typeof premiumFeatures = premiumFeatures;

  protected readonly isMobileLayout: Signal<boolean> = this._layoutStore.isMobileLayout;

  protected readonly yearlyPrice: Signal<string> = computed(() => {
    return this.freeTrialPremiumPlan().overallPrice ?? FALLBACK_PRICE.YEARLY;
  });

  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    @Inject(PURCHASE_SERVICE) private readonly _purchaseService: PurchaseBaseService,
    private readonly _layoutStore: LayoutStore,
    private readonly _dialogRef: DialogRef,
  ) {
    this._analyticsService.trackEvent({ action: 'premium_modal_second_session_view' });
  }

  protected closeClicked(): void {
    this._analyticsService.trackEvent({
      action: 'premium_modal_second_session_interact',
      properties: { action: 'close' },
    });
  }

  protected async onTryPremiumClicked(): Promise<void> {
    this._analyticsService.trackEvent({
      action: 'premium_modal_second_session_interact',
      properties: { action: 'get_premium' },
    });

    const hasPurchased = await this._purchaseService.purchasePremiumPlan({
      premiumPlan: this.freeTrialPremiumPlan(),
      source: 'session_start',
    });

    if (hasPurchased) {
      this._dialogRef.dismiss();
    }
  }
}
