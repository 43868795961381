<img src="assets/shared/svgs/hand.svg" alt="" />

<div class="content-container">
  <h3 class="display-md-semibold">
    {{ "PREMIUM.INTRODUCTION_DIALOG.HEADING" | translate: { userFirstName: (userFirstName$ | async) ?? "" } }}
  </h3>

  <p class="text-lg-regular">
    {{ "PREMIUM.INTRODUCTION_DIALOG.CONTENT" | translate }}
  </p>
</div>

<button class="primary-button" uiButton primaryOnTheme size="xl" uiDialogClose (click)="continue()">
  {{ "GLOBAL.CONTINUE" | translate }}
</button>
