import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { HasPremiumDialogCloseEvent } from '@stsm/premium/models/has-premium-dialog-close-event';
import { HasPremiumDialogMoneyBackGuaranteeEvent } from '@stsm/premium/models/has-premium-dialog-money-back-guarantee-event';
import { HasPremiumDialogPurchaseEvent } from '@stsm/premium/models/has-premium-dialog-purchase-event';
import { PremiumPlan } from '@stsm/premium/models/premium-plan';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { PillComponent } from '@stsm/ui-components/pill';

import { PremiumFeaturesComponent } from '../premium-features/premium-features.component';

@Component({
  selector: 'app-premium-discount-dialog',
  imports: [NgIf, ButtonComponent, TranslatePipe, PremiumFeaturesComponent, PillComponent, IconButtonComponent],
  templateUrl: './premium-discount-dialog.component.html',
  styleUrl: './premium-discount-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PremiumDiscountDialogComponent
  implements HasPremiumDialogMoneyBackGuaranteeEvent, HasPremiumDialogCloseEvent, HasPremiumDialogPurchaseEvent
{
  @Input({ required: true }) premiumPlan: PremiumPlan | undefined;

  @Output()
  readonly purchasePlan: EventEmitter<PremiumPlan> = new EventEmitter<PremiumPlan>();

  @Output()
  readonly moneyBackGuaranteeClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  readonly closeClicked: EventEmitter<void> = new EventEmitter<void>();

  protected purchase(): void {
    this.purchasePlan.emit(this.premiumPlan);
  }
}
