import { afterNextRender, ChangeDetectionStrategy, Component, EventEmitter, input, InputSignal, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';
import { catchError, switchMap, tap, throwError } from 'rxjs';

import { PremiumModalCallSource } from '@stsm/analytics/models/source-property-types';
import { AuthStore } from '@stsm/auth/data/auth-store.service';
import { TranslationService } from '@stsm/i18n/global/services/translation.service';
import { PremiumPlan } from '@stsm/premium/models/premium-plan';
import { ProjectedRevenueData } from '@stsm/premium/models/purchase-result';
import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { LoadingService } from '@stsm/ui-components/loading-dialog/loading.service';
import { PaymentProvider } from '@stsm/user/models/payment-provider';

import { environment } from '../../../../environments/environment';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { PremiumService } from '../../../shared/services/premium.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let paypal: any;

@UntilDestroy()
@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaypalComponent {
  plan: InputSignal<PremiumPlan> = input.required<PremiumPlan>();
  source: InputSignal<PremiumModalCallSource> = input.required<PremiumModalCallSource>();
  newPaymentSelectionExperiment: InputSignal<boolean> = input<boolean>(false);

  @Output() readonly transactionCompleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly transactionInProcess: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private readonly _analyticsService: AnalyticsService,
    private readonly _loadingService: LoadingService,
    private readonly _premiumService: PremiumService,
    private readonly _translationService: TranslationService,
    private readonly _simpleDialogService: SimpleDialogService,
    private readonly _authStore: AuthStore,
  ) {
    /**
     * afterNextRender is used here because PayPal needs the elements in the DOM to be available.
     */
    afterNextRender(() => {
      if (this.plan()) {
        this._setupSubscriptionButtons(this.plan());
      }
    });
  }

  private _setupSubscriptionButtons(plan: PremiumPlan): void {
    if (this.newPaymentSelectionExperiment()) {
      // paypal button
      paypal
        .Buttons({
          style: {
            color: 'white',
            borderRadius: 8,
            label: 'paypal',
            height: 55,
          },
          fundingSource: paypal.FUNDING.PAYPAL,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          createSubscription: (_: any, actions: any) => this._handleCreateSubscription(plan, actions),
          onClick: (data: { fundingSource: string }) => this._handleClick(data),
          onApprove: (data: { subscriptionID: string; orderID: string }) => this._handleApprove(plan.productID, data),
          onCancel: () => this._handleCancel(),
        })
        .render('#paypal-button-container');

      // Card button
      paypal
        .Buttons({
          style: {
            color: 'white',
            borderRadius: 8,
            label: 'pay',
            height: 55,
          },
          fundingSource: paypal.FUNDING.CARD,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          createSubscription: (_: any, actions: any) => this._handleCreateSubscription(plan, actions),
          onClick: (data: { fundingSource: string }) => this._handleClick(data),
          onApprove: (data: { subscriptionID: string; orderID: string }) => this._handleApprove(plan.productID, data),
          onCancel: () => this._handleCancel(),
        })
        .render('#paypal-card-button-container');
    } else {
      paypal
        .Buttons({
          style: {
            color: 'blue',
            shape: 'pill',
            label: 'pay',
            height: 40,
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          createSubscription: (_: any, actions: any) => this._handleCreateSubscription(plan, actions),
          onClick: (data: { fundingSource: string }) => this._handleClick(data),
          onApprove: (data: { subscriptionID: string; orderID: string }) => this._handleApprove(plan.productID, data),
          onCancel: () => this._handleCancel(),
        })
        .render('#paypal-button-container');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _handleCreateSubscription(plan: PremiumPlan, actions: any): Promise<{ subscriptionID: string }> {
    this._analyticsService.trackEvent({
      action: 'premium_plan_purchase_init',
      properties: {
        productID: plan.productID,
        source: this.source(),
      },
    });

    return actions.subscription.create({
      plan_id: plan.productID,
      custom_id: this._authStore.userId,
    });
  }

  private _handleClick(data: { fundingSource: string }): void {
    localStorage.setItem(GlobalLocalStorageKey.PREMIUM_PURCHASE_PENDING, 'true');
    this._analyticsService.trackEvent({
      action: 'premium_web_payment_method_click',
      properties: {
        type: data.fundingSource,
      },
    });
  }

  private _handleApprove(productID: string, data: { subscriptionID: string; orderID: string }): void {
    localStorage.removeItem(GlobalLocalStorageKey.PREMIUM_PURCHASE_PENDING);
    this._loadingService.showLoading(this._translationService.get('PREMIUM.PLANS.ACTIVATING'));
    this.transactionInProcess.emit(true);
    this._onSubscriptionApproved(productID, data.subscriptionID, data.orderID);
  }

  private _handleCancel(): void {
    localStorage.removeItem(GlobalLocalStorageKey.PREMIUM_PURCHASE_PENDING);
    this._analyticsService.trackEvent({ action: 'premium_plans_purchase_plan_cancel' });
  }

  private _onSubscriptionApproved(productId: string, subscriptionId: string, orderId: string): void {
    this._analyticsService.trackEvent({ action: 'premium_validate_receipt' });
    this._premiumService
      .validateSubscriptionReceipt(subscriptionId, orderId)
      .pipe(
        switchMap((projectedRevenueData: ProjectedRevenueData) => {
          this._analyticsService.trackEvent({
            action: 'premium_receipt_validation_finish',
            properties: {
              provider: PaymentProvider.PAYPAL,
              success: true,
            },
          });

          this._analyticsService.trackEvent({
            action: 'premium_plans_purchase_plan_success',
            properties: {
              subscriptionId: !isNil(subscriptionId),
              orderID: !isNil(orderId),
              productId,
              source: this.source(),
              provider: PaymentProvider.PAYPAL,
            },
            revenueProperties: {
              revenue: projectedRevenueData.projectedRevenue,
              revenueType: 'purchase',
              isProjectedRevenue: true,
            },
          });

          this._analyticsService.setAmplitudeUserProperty('premium_conversion_flow', this.source());
          this._loadingService.hideLoading();

          return this._premiumService.refreshPremiumInfo();
        }),
        tap(() => {
          this.transactionInProcess.emit(false);
          this.transactionCompleted.emit();
        }),
        catchError((err: Error) => {
          this._analyticsService.trackEvent({
            action: 'premium_receipt_validation_finish',
            properties: {
              provider: PaymentProvider.PAYPAL,
              success: false,
            },
          });
          this._loadingService.hideLoading();
          this._simpleDialogService.showError('PREMIUM.PLANS.ERROR_VALIDATION');

          if (!environment.production) {
            console.error(err);
          }

          return throwError(() => err);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
