@if (newPaymentSelectionExperiment()) {
  <div class="header">
    @if (!isMobileLayout()) {
      <button uiIconButton secondary class="close-button" [disabled]="isTransactionInProcess" (click)="onBack()">
        <i class="app-icon-chevron-back"></i>
      </button>
    }

    <button uiIconButton secondary class="close-button" [disabled]="isTransactionInProcess" (click)="onCloseDialog()">
      <i class="app-icon-close"></i>
    </button>
  </div>

  <div class="content">
    @if (premiumPlan()) {
      @if (!isMobileLayout()) {
        <h3 class="title">{{ "PREMIUM.PLANS.SELECTED_PLAN" | translate }}</h3>

        <div class="plan-info">
          <img aria-hidden alt="" height="40" width="40" [src]="LogoSource.HAT_DEFAULT" />

          <div class="plan-details">
            <span class="plan-title">{{ "PREMIUM.PLANS.PREMIUM_MEMBERSHIP" | translate }}</span>

            <div class="plan-description">
              @if (!usedFreeTrial()) {
                <span class="subtitle" data-cy="payment-dialog-subtitle-free-trial-info" [innerHTML]="priceInfo()"></span>
              }

              <span class="subtitle">({{ premiumPlan().overallPrice }} / {{ premiumPlan().durationKey | translate }})</span>
            </div>
          </div>
        </div>

        <hr class="separator" />
      }
      <h3 class="title">{{ "PREMIUM.PLANS.PAYMENT_METHOD" | translate }}</h3>

      <app-paypal
        [plan]="premiumPlan()"
        [source]="source"
        [newPaymentSelectionExperiment]="newPaymentSelectionExperiment()"
        (transactionCompleted)="onTransactionCompleted()"
        (transactionInProcess)="onTransactionInProcess($event)"
      />
    }
  </div>
} @else {
  <button uiIconButton tertiary class="close-button" [disabled]="isTransactionInProcess" (click)="onCloseDialog()">
    <i class="app-icon-close"></i>
  </button>

  <div class="content">
    @if (premiumPlan()) {
      <p class="title">{{ "PREMIUM.PLANS.PREMIUM_MEMBERSHIP" | translate }}</p>
      @if (!usedFreeTrial()) {
        <p class="subtitle" data-cy="payment-dialog-subtitle-free-trial-info" [innerHTML]="priceInfo()"></p>
      }
      <p class="subtitle">({{ premiumPlan().overallPrice }} / {{ premiumPlan().durationKey | translate }})</p>
    }

    <div class="button-wrapper">
      <div class="payment-wrapper" [class.inactive]="!premiumPlan()">
        <app-paypal
          [plan]="premiumPlan()"
          [source]="source"
          (transactionCompleted)="onTransactionCompleted()"
          (transactionInProcess)="onTransactionInProcess($event)"
        />
      </div>

      @if (isFakeDoorTreatment()) {
        <ui-or-separator />
        <app-payment-provider-fake-door />
      }
    </div>
  </div>
}
